import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { Layout } from 'src/components/Layout/Layout';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import { EditProductPageRules } from './EditProductPage.style';
import useLoader from 'src/hooks/useLoader';
import { RoutePath } from 'src/providers/AppRouter/routeConfig';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { ModifiersForProduct } from 'src/blocks/create-product/ModifiersForProduct/ModifiersForProduct';
import { ProductForm } from 'src/blocks/create-product/ProductForm/ProductForm';
import { CategoriesForProduct } from 'src/blocks/create-product/CategoriesForProduct/CategoriesForProduct';
import { getProductDetail } from 'src/redux/api/products/getProductDetaitl';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { useModalState } from 'src/hooks/useModalState';
import ModalError from 'src/components/ModalError/ModalError';
import { useScrollViewport } from 'src/hooks/useScrollViewport';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

export const EditProductPage: FC = memo(function EditProductPage(props) {
  const {} = props;
  const { css } = useStyle(EditProductPageRules);
  const BackButton = useBackButton();
  const { hideLoader, showLoader } = useLoader();
  const navigate = useNavigate();
  const { productId } = useParams();
  const dispatch = useAppDispatch();
  const [renderErrorModal, activeErrorModal, openErrorModal, closeErrorModal] = useModalState();
  const {
    installingModifiers,
    initialProductData,
    detailsNetworkStatus,
    installingCategories,
    saveStateError,
  } = useAppSelector(state => state.Product);

  const { t } = useTranslation()

  useEffect(() => {
    dispatch(getProductDetail(productId));
  }, [dispatch, productId]);

  useEffect(() => {
    // if (detailsNetworkStatus === NetworkStatus.None) {
    //   dispatch(getProductDetail(productId));
    // }

    // if (
    //   initialProductData &&
    //   initialProductData.uuid !== productId &&
    //   detailsNetworkStatus !== NetworkStatus.Loading &&
    //   detailsNetworkStatus !== NetworkStatus.Failed
    // ) {
    //   dispatch(getProductDetail(productId));
    // }

    if (detailsNetworkStatus === NetworkStatus.Loading) {
      showLoader();
    }
    if (detailsNetworkStatus === NetworkStatus.Done) {
      hideLoader();
    }
    if (detailsNetworkStatus === NetworkStatus.Failed) {
      navigate(-1)
    }
  }, [detailsNetworkStatus, navigate, showLoader, hideLoader]);

  useEffect(() => {
    BackButton.onClickCustom(goBack);

    function goBack() {
      navigate(RoutePath.Products);
    }

    if (installingCategories) {
      BackButton?.offClick(goBack);
    }

    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, installingCategories, navigate]);

  useEffect(() => {
    if (saveStateError) openErrorModal();
  }, [saveStateError, openErrorModal]);

  const [layoutEl, setLayoutEl] = useState({ current: null });

  const layoutRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setLayoutEl({ current: node });
    }
  }, []);

  useScrollViewport({scrollElementRef: layoutEl});

  if (installingModifiers) {
    return <ModifiersForProduct />;
  }

  if (detailsNetworkStatus !== NetworkStatus.Done) {
    return null;
  }

  return (
    <>
      <Layout
        header={
          <header className={`${css.header} header`}>
            <Text text={t('editProductPage.product')} mod="title" fontWeight={800} fontSize={20} lineHeight={22} />
          </header>
        }
        ref={layoutRef}
      >
        <Container extend={css.container}>
          <ProductForm product={initialProductData} editingProduct={true} />
        </Container>
      </Layout>
      {installingCategories && <CategoriesForProduct />}
      {renderErrorModal && (
        <ModalError
          onClose={closeErrorModal}
          title={'Ошибка'}
          text={saveStateError}
          active={activeErrorModal}
        />
      )}
    </>
  );
});
